<template>
    <div>
        <Navbar />
        <div class="root_terms">
            <div class="heading_terms">
                Terms And Conditions
            </div>
            <div class="text_terms">
                <div class="pa" v-for="(i, indx) in arr" :key="indx">
                    <div class="heading__">
                        {{ i.heading }}
                    </div>
                    <div class="content__">
                        <div v-for="(j, indx2) in i.content" :key="indx2">
                            <div v-if="Array.isArray(j)" class="internal-for">
                                <ol>
                                    <li v-for="(k, indx3) in j" :key="indx3">
                                        {{ k }}
                                    </li>
                                </ol>
                            </div>
                            <div class="else__" v-else>
                                {{ j }}
                            </div>
                        </div>
                    </div>
                </div>


                <!--  -->
                <b>How do I know current status of my order?</b>
                <p>
                    You can track the status of your order with the details sent to your email. You can also track the same
                    in
                    the dashboard.
                </p>
                <b>How do I know if my order is placed successfully?</b>
                <p>
                    Once your order is successfully placed, you will be directed to a confirmation page to make the payment.
                    In
                    addition, you will also receive an email confirmation with the order number, you can track status of
                    your
                    order with the order number.

                </p><b>Where do the items get sent?</b>
                <p>
                    Items bought in the YIC store are automatically received at the warehouse and added into the Suite. If
                    the
                    item order is a sweet or snack then it is made to order and added into the suite/package just before it
                    is
                    ready for shipping. This is done to make sure the food item is procured fresh and sent to the customer
                    overseas fresh.



                </p><b>Can I cancel my order once it placed?</b>
                <p>
                    All purchases made in the YIC store are final. The reason it cannot be returned is because the items are
                    procured specially for you once the order is placed in the store. We don’t keep any items in stock.

                </p><b>What is your return policy?</b>
                <p>
                    All sales are final when bought at the YIC store. We procure the items specially for you once an order
                    is
                    confirmed and paid on the YIC store. We don’t stock any items in our warehouse. If an incorrect item was
                    sent then we will refund/exchange the item.

                    For returns, please email support@yourindiancart.com with the subject 'Returns' and include your suite
                    number, name and the reason for the return.
                    We will provide you the instructions where to send returned goods.
                    Please note that customers are responsible for shipping costs of returned items.
                    When returned item is received and inspected, customer receives a confirmation email, notifying about
                    the
                    status of the refund.
                    If the refund is approved, then it will be processed, and a credit will automatically be applied to
                    customer's credit card or original payment method shortly after.
                </p>






                <!--  -->

                <div class="heading__">6.0 Additional Services</div>

                <div class="heading__">6.1 Package Pick Up Service</div>


                YourIndianCart can pick your shipments from anywhere in South India for free. However, we are not
                responsible for the quality of the goods of the items delivered. One of the conveniences
                YourIndianCart
                provides to its customers is picking packages on behalf of its customers from anywhere in India for
                a fee
                except South India which is free of charge. Only 1 free pick-up request is allowed per package.
                YourIndianCart will only be a facilitator of package pickup on behalf of its customers.
                YourIndianCart leverages third party Domestic Indian courier services to arrange for package pickup
                from
                anywhere in India. So, all policies that apply to the third party are also applicable in this
                transaction.
                It is Customer’s responsibility to:
                Ensure the package is properly sealed, items are properly wrapped to avoid any damages during
                transit.
                Ensure packing slip is printed and properly pasted to the box. If needed keep one packing slip
                printout
                inside the box.
                No prohibited items allowed, refer services page.
                Customer to provide the list of items that goes into the package. YourIndianCart will validate the
                items in
                the package once received with the list provided. 
                Customer is responsible for the return of the items if items are not allowed to be shipped to the
                overseas
                country.
                Customers are free to opt for domestic package insurance to safeguard against damages if any is
                caused to
                the package. 
                YourIndianCart cannot be held responsible for any damages to the items in the package from the time
                the
                third-party courier service picks up the package and delivers it to YourIndianCart suite.
                YourIndianCart is not responsible for any lost packages or lost items in the package. 



                <div class="heading__">(a) Your Obligations</div>


                You warrant that each Shipment is properly described along with all relevant documents, acceptable
                for
                transport by us, and properly marked, addressed (including postal code), and packed to ensure safe
                transportation with ordinary care in handling. You are responsible for and shall indemnify us
                against all
                charges and possible surcharges and assessments including fees related to our prepayment of the
                same,
                governmental penalties and fines, taxes, and our lawyers’ fees and legal costs, related to this
                Shipment.
                You shall not book / handover or allow to be handed over any Shipment which is banned, restricted,
                illegal,
                prohibited, stolen, infringing of any third-party rights, hazardous or dangerous or in breach of any
                tax
                laws.



                <div class="heading__">(B) Limited Liability</div>


                Our maximum liability for the damage, loss, theft or misappropriation of any Shipment during the
                transit
                period shall be INR 5,000 or the actual aggregate value of the product, whichever is lower.



                <div class="heading__">7.0 Customer Responsibilities</div>

                <div class="heading__">7.1 Import and Export Documentation</div>


                The customer is responsible for determining and following the regulations for their shipments,
                including all
                customs requirements of the destination country. YourIndianCart does not guarantee delivery of
                packages that
                do not comply with Indian export regulations or the import regulations of the country to which they
                are
                sent. You are responsible for the accuracy of all customs declarations and documentation and for all
                customs
                duties in the destination country. Any such duties or charges are in addition to the fees earned by
                YourIndianCart for its package forwarding service. You acknowledge that you have a duty to and are
                solely
                liable for providing all information required by the laws of the country that is the final
                destination of
                your package and the laws and regulations of India.
                YourIndianCart will act as a service provider of receiving & handling the customer’s products, which
                will be
                sorted, consolidated (if requested), re-packed (if requested), stored and exported. YourIndianCart
                will
                arrange for export to customer’s specified international address by an unaffiliated service
                provider, which
                acts as the freight forwarder and/or carrier for the shipment. YourIndianCart does not act as an
                indirect
                air carrier, but rather acts as a service provider where the shipper’s identification captured in
                the portal
                is passed on to the unaffiliated service provider which is used for shipping purposes.
                YourIndianCart team
                will open all packages upon arrival at our facility. Unaffiliated service provider will provide any
                of the
                following services to customers: preparation of shipping documents, preparation of any certificates
                of
                origin and preparation of an airway bill or other documents required for exporting item(s) to the
                customer.
                The customer acknowledges that he/she has a duty to – and is solely liable for – accurately
                providing all
                information required by Indian laws and regulations.
                Customers will be held responsible for providing correct product category, product description,
                number of
                units and total invoice number of exported products. If needed, the customer is obligated to send
                YourIndianCart a commercial invoice for custom clearance purposes.



                <div class="heading__">7.2 Shipping</div>


                YourIndianCart uses reputed couriers like UPS, DHL, FEDEX and other similar couriers based on
                service
                availability. YourIndianCart is not responsible for the actual shipping service. All terms and
                conditions of
                the above-mentioned shipping companies apply to you. You are responsible for assuring that the
                shipping of
                your package is not restricted by its contents, size or volume. We strongly recommend reading their
                terms
                and conditions before shipping.
                By accepting our Terms and Conditions you accept our shipping partners Terms and Conditions as well.
                You
                authorize YourIndianCart to open packages if there is a suspicion about the content of the package
                (illegal
                goods) or any practical reason.
                Any problems associated with a received package must be reported within 15 days of delivery with a
                package
                opening video. Customers are required to take a package opening video when they receive packages at
                the
                doorstep worldwide.
                Shipping costs are based on the weight and dimensions of Customer’s shipments. These costs will be
                charged
                according to the actual weight or dimensional weight (whichever is greater). Dimensional weight will
                be
                calculated according to unaffiliated freight forwarder’s terms.
                The Customer is responsible for customs/ duties/ charges and will not hold YourIndianCart against
                any of the
                associated charges and/or delays arising due to customs clearance.
                The Customer could be charged for the extra weight and additional processing charges occurring due
                to change
                in dimensions/weight declared and also the shipments could be stopped due to discrepancy in
                dimensions
                specified.
                YourIndianCart is not responsible for shipment held for any reason beyond our control like
                government
                agencies, natural calamities or unforeseen events.
                The Customer also agrees not to ship any prohibited items listed in the services page and is solely
                responsible for the consequences arising out of it if sent.
                Return shipment charges and also possible custom charges will be borne by the Customer. Claims on
                Shipment
                would be entertained only upon insurance of the packages. YourIndianCart is not liable for any
                delays caused
                during transporting a shipment.



                <div class="heading__">7.3 Billing Problems</div>


                Customer is responsible for reviewing all charges from YourIndianCart. Any suspected discrepancies
                in
                billing must be reported to YourIndianCart within 30 days of the billing date. After 30 days, no
                charges are
                refundable.



                <div class="heading__">7.4 Other Rules</div>


                You agree to comply with all laws and regulations applicable to your access and use of
                YourIndianCart.com.
                You may not harvest personal data (including email addresses) from YourIndianCart.com, and
                specifically you
                may not use email addresses displayed on our sites to ask users to join or contribute to your
                services. We
                have the right to investigate and prosecute violations of any of the above to the fullest extent of
                the law.
                We may involve and cooperate with law enforcement authorities in prosecuting users who violate the
                terms of
                this Agreement. You acknowledge that YourIndianCart has no obligation to monitor your access to or
                use of
                YourIndianCart.com, but has the right to do so for the purpose of operating YourIndianCart.com, to
                ensure
                your compliance with this Agreement, or to comply with applicable law or the order or requirement of
                a
                court, administrative agency or other governmental body. Unless we explicitly agree otherwise in
                writing,
                you may not use any automated means (such as scripts) to access YourIndianCart.com or collect
                information
                from it.



                <div class="heading__">7.5 YourIndianCart Liability</div>


                YourIndianCart’s total responsibility to Customer for shipped packages shall not exceed the amount
                of our
                fees (excluding actual shipping fees).
                Customers shall indemnify and hold YourIndianCart harmless from any and all claims, including legal
                fees
                incurred in defending against said claims, from third parties arising out of the transmission of the
                package
                sent by customer through YourIndianCart.
                YourIndianCart shall not be responsible for any damage from an error or omission caused by you
                providing
                false or incorrect information or for failing to provide all necessary information.



                <div class="heading__">7.6 Problems with Delivery to The YourIndianCart Facility</div>


                You are responsible for confirming that the package and the contents of the package that arrived in
                our
                warehouse is what you ordered from the merchant. YourIndianCart provides photos of outside of each
                incoming
                package so you can ascertain if the correct package is received and whether or not the address and
                tracking
                number are accurate. In the event of a shipping error, you agree to immediately notify
                YourIndianCart so
                that the error can be corrected.



                <div class="heading__">7.7 Damaged, Missing and Lost Packages</div>


                In the case of a lost or damaged package please contact support@YourIndianCart.com to file a claim.
                False
                claims will be charged a minimum INR 500 plus YourIndianCart’s investigation expenses. All claims
                for lost
                or damaged packages must be reported to YourIndianCart within 10 days otherwise they will be
                automatically
                denied. Customers are advised to take package opening video when the package arrives at their
                doorstep.
                Packages are insured by carrier shipping insurance. Insurance for packages lost in the warehouse or
                lost
                during shipping will be valued based on the completed product value in the dashboard. You may be
                asked to
                submit additional information detailing item contents and/or value of the merchandise during the
                claims
                process. YourIndianCart.com will not be held accountable if item(s) are stolen or taken from
                Customer’s
                doorstep by someone other than Customer or affiliated persons. YourIndianCart.com will not be held
                responsible if a local courier drops off an item(s) at the wrong address.



                <div class="heading__">7.8 Delays</div>


                Sometimes YourIndianCart needs more time to locate your package in our warehouse. YourIndianCart
                cannot
                offer any additional compensation for the delay in locating and process of your package. If we send
                a
                package to an address not listed on your account, we will do everything we can to get the package to
                the
                correct address. We will cover the cost of shipping and handling, but we will not compensate for
                delays or
                inconvenience. If we cannot get a package to the correct person we will compensate for the content,
                our fees
                and postage.



                <div class="heading__">7.9 Termination</div>


                You agree that YourIndianCart, in its sole discretion, may terminate your access to any of the
                Services,
                and/or remove, discard or modify any content within the Services, for any reason, including, without
                limitation, for lack of use or if YourIndianCart believes that you have violated or acted
                inconsistently
                with the letter or spirit of this Agreement. You agree that any termination of your access to the
                Services
                may be affected without prior notice, and acknowledge and agree that YourIndianCart may immediately
                deactivate or delete your account and all related information and files in your account and/or bar
                any
                further access to such files or the Services. If you use the Site in violation of this Agreement,
                YourIndianCart may, in its sole discretion, retain all data collected from your use of the Site.
                Further,
                you agree that YourIndianCart shall not be liable to you or any third party for the discontinuation
                or
                termination of your access to the Services, or collection of information notwithstanding in the case
                of your
                violation of this Agreement, even if advised of a claim for damages. If YourIndianCart terminates
                your
                license to access the Services, your obligations under this Agreement, including, without
                limitation, your
                obligation to indemnify and hold YourIndianCart harmless and your agreement that any claims you have
                against
                YourIndianCart will be barred after one year from the date they accrue, will survive such
                termination.
                You can cancel your account at any time by contacting Customer Support support@YourIndianCart.com.
                We can
                cancel your account or otherwise refuse service to you at any time for any reason (including repeat
                infringement).


                <div class="heading__">8.0 Insurance, Refund and Cancellation Policy</div>


                Shipping charges are non-refundable. YourIndianCart’s liability in respect of any one Shipment
                transported
                by air (including ancillary road transport or stops
                enroute) is limited by the Montreal Convention or the Warsaw Convention as applicable, or in the
                absence of
                such Convention, to the lower of (i) the current market or declared value, or (ii) 19 Special
                Drawing Rights
                per kilogram (approximately $US 26.00 per kilogram). Such limits shall also apply to all other forms
                of
                transportation, except where Shipments are carried only by road, when the limits below apply.
                For cross border Shipments transported by road, YourIndianCart liability is or shall be deemed to be
                limited
                by the Convention for the International Carriage of Goods by Road (CMR) to the lower of (i) current
                market
                value or declared value, or (ii) Special Drawing Rights per kilogram (approximately $US 14.00 per
                kilogram).
                Such limits will also apply to national road transportation in the absence of any mandatory or lower
                liability limits in the applicable national transport law.
                If a customer regards these limits as insufficient, the customer must make a special declaration of
                value
                and request Shipment Value Protection or make their own insurance arrangements.
                YourIndianCart may be able to arrange Shipment Protection Insurance covering the value in respect of
                loss of
                or damage to the Shipment, provided that the customer pays the applicable premium as shown in the
                dashboard
                in “Ready to ship” section. Shipment protection insurance does not cover indirect loss or damage, or
                loss or
                damage caused by delays.
                YourIndianCart will make every reasonable effort to deliver the Shipment according to
                YourIndianCart’s
                regular delivery schedules, but these schedules are not binding and do not form part of the
                contract.
                YourIndianCart is not liable for any damages or loss caused by delay.



                <div class="heading__">8.1 YourIndianCart Return Service</div>


                One of the conveniences YourIndianCart provides to its customers is facilitating returns on behalf
                of its
                customers. YourIndianCart will only be a facilitator of returns on behalf of its customers and does
                not
                control Seller’s return policy terms and refunds. 
                Note: YourIndianCart can help with facilitation of returns only for goods that have not been shipped
                to the
                Customer overseas address. If the customer wishes to return the product after it is delivered to his
                preferred address, the customer will have to send the product back to YourIndianCart address at
                customer’s
                expense. YourIndianCart can further help return the item only if a return is supported by the
                seller.



                <div class="heading__">8.2 Seller Return Policy</div>


                When you request return service from YourIndianCart for an order from a Seller (also called a
                third-party
                seller), YourIndianCart provides you the service to package and ship the item back to the original
                seller.
                While most sellers offer a return policy, some sellers may not accept returns.
                Customers should review the return policy of the Seller before a request is made to YourIndianCart
                to return
                an item.
                Customers responsibility to place a return request at the Sellers end first before requesting the
                return on
                the YourIndianCart dashboard.
                Some Sellers may charge an additional cost when returning items such as shipping and handling fees.
                This
                cost is the responsibility of the Customer.



                <div class="heading__">8.3 Items that cannot be returned after purchase</div>


                In situations where items are not returnable (either due to elapsed return due dates, Seller
                not
                agreeing to
                take returns or any other reason), YourIndianCart can assist its customer with following
                options –
                YourIndianCart can ship the items to local India address that the customer provides at
                additional
                cost to
                customer
                YourIndianCart can help with donating the items
                YourIndianCart can help with discarding the items with written permission from customer
                Note – YourIndianCart will not be able to provide refunds on behalf of the Sellers. If
                Seller does
                not
                accept return, the Customer will have the above 3 options and no refund money is to be
                expected from
                YourIndianCart



                <div class="heading__">9.0 Miscellaneous</div>
                <div class="heading__">9.1 Indemnity</div>


                By choosing to use YourIndianCart.com, you agree to indemnify YourIndianCart, its officers,
                agents,
                partners
                and employees from any and all claims or damage, including reasonable lawyer’s fees, made by
                third
                parties
                due to or arising out of 1) content you choose to submit, post or transmit through
                YourIndianCart.com; 2)
                your use of or connection to YourIndianCart.com; 3) your violation of the User Agreement; or
                4) your
                violation of any rights of another.



                <div class="heading__">9.2 Release</div>


                In the event that you have a dispute with one or more users (including merchants and service
                professionals)
                of YourIndianCart.com, you, on behalf of yourself (and any of your officers, directors,
                employees,
                agents,
                predecessors, successors and assigns), hereby irrevocably release and discharge
                YourIndianCart, our
                officers, directors, employees, attorneys, predecessors, successors, assigns and agents
                from,
                against and in
                respect of all past, present and future claims, rights, actions, causes of action, suits,
                indemnification
                obligations, losses, liabilities, matters, issues, cost and expenses, of any kind or nature
                whatsoever,
                including without limitation court costs and lawyers’ fees, whether known or unknown,
                concealed or
                hidden,
                fixed or contingent, suspected or unsuspected, in law or in equity, concerning, related to
                or
                arising out of
                any such disputes.



                <div class="heading__">9.3 Governing Law</div>


                You agree not take any legal action against us that relates to or arises out of any of our
                websites
                without
                first (a) sending us, via registered mail or national overnight courier service, a detailed
                written
                description of the facts and law out of which your claim arises; and (b) negotiating with
                us, in
                good faith,
                for not less 30 days, toward resolution of the dispute. All correspondence concerning such
                disputes
                must be
                sent to support@YourIndianCart.com, or such other designee as YourIndianCart may identify
                from time
                to time.
                The Website Terms shall be governed by and construed in accordance with Indian law. Disputes
                arising
                in
                connection with these Website Terms (including non-contractual disputes) shall be subject to
                the
                exclusive
                jurisdiction of the courts of Hyderabad, Telangana (India). All dealings, correspondence and
                contacts
                between us shall be made or conducted in the English language.



                <div class="heading__">9.4 Entire Agreement</div>


                This Agreement, and our Privacy Policy (which is incorporated herein by reference)
                constitute the
                entire
                Agreement and supersede any other Agreements or understandings (oral or written) between you
                and us
                with
                respect to their subject matters.


                <div class="heading__">9.5 Disclaimer of Warranties.</div>


                YourIndianCart.com and its services are provided “as is,” without express or implied
                warranty or
                condition
                of any kind, and we explicitly disclaim any warranties of merchantability, fitness for a
                particular
                purpose,
                quiet enjoyment or non-infringement. We do not promise or warrant that any aspect of the
                sites or
                system
                will work properly or that the information provided is complete or accurate or will be
                continuously
                available. YourIndianCart.com may contain links to third-party websites or resources. You
                acknowledge and
                agree that we are not responsible or liable for: (1) the availability or accuracy of such
                websites
                or
                resources; or (2) the content, products or services on or available from such websites or
                resources.
                Links
                to such websites or resources do not imply any endorsement of such websites or resources or
                the
                content,
                products or services available from such websites or resources. You acknowledge sole
                responsibility
                for and
                assume all risk arising from your use of any such websites or resources. Your correspondence
                with or
                participation in promotions of merchants or advertisers found on YourIndianCart.com,
                including
                payment and
                delivery of related goods or service, and any other terms, conditions, warranties or
                representations
                associated with such dealings, are solely between you and such merchant or advertiser. You
                agree not
                to hold
                YourIndianCart liable for any loss or damage of any sort incurred as the result of any such
                dealings
                or as
                the result of the presence of such merchants or advertisers on YourIndianCart.com. In
                addition,
                YourIndianCart does not endorse, warrant or guarantee the products or services of any seller
                or
                service
                professional.



                <div class="heading__">9.6 Force Majeure</div>


                YourIndianCart shall not be liable to any party for any delay or failure due to: acts of
                God, war,
                transportation difficulties, labor strikes, natural disasters, riots, telecommunications or
                information
                services infrastructure, hacking, spam, any failure of a computer, server, or software, or
                acts or
                omissions
                of vendors or suppliers beyond the control of YourIndianCart.



                <div class="heading__">9.7 Severability</div>


                If any provision of this Agreement is held invalid or unenforceable by a court of competent
                jurisdiction,
                the remaining provisions of the Agreement will remain in full force and effect, and the
                provision
                affected
                will be construed so as to be enforceable to the maximum extent permissible by law.
                9.8 No Waiver
                Any waiver of any provision of this Agreement will be effective only if in writing and
                signed by
                YourIndianCart.


            </div>
        </div>
        <FooterYic />
    </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import Navbar from "../navbar/Navbar.vue";
import FooterYic from '../homepage/footer.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
} from "bootstrap-vue";
export default {
    components: {
        BButton,
        BModal,
        BAlert,
        Navbar,
        BRow,
        BImg,
        BCol,
        BLink,
        BForm,
        BCardText,
        BCardTitle,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BInputGroup,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        FooterYic,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            arr: [
                {
                    heading: 'Introduction',
                    content: [
                        'www.YourIndianCart.com offers parcel forwarding, online Store along with package consolidation services from India to USA. When you sign up for an account, you will receive a virtual Indian Address and Suite Number where we can receive your packages. When YourIndianCart receives a package addressed to you, we will add them into your Suite and we will notify you. This can be viewed on the dashboard under the Items in warehouse tab. Once all items are received you can request consolidation after which we will send the package on to your address in your home country',
                        'YourIndianCart provides free membership which includes the following.',
                        [
                            'FREE Indian Address',
                            'FREE Package receiving',
                            'FREE 3 Photo of each item received',
                            'FREE item verification for damage',
                            'FREE Storage up to 30 days',
                            'FREE Package Return processing of your purchased items domestically within India contingent upon seller’s return policy.',
                            'FREE Consolidation of multiple packages',
                            'FREE efficient packing to ensure no valuable space goes unused.',
                            'FREE E-mail updates',
                            'FREE real time shipment tracking',
                            'FREE My Dashboard utility to track items and package status',
                            'FREE Pickup from anywhere in South India',
                            'FREE Customs declaration paperwork',
                            'Shop items from YIC store ',
                            'Pay for all services using your overseas credit/debit card, Indian credit/debit card and Net Banking  ',
                        ],
                        'YourIndianCart service charges for one shipping order - INR 500.',
                        'Note - YourIndianCart service charges include all the above items including shipping boxes, packaging materials plus all the items above.'
                    ]
                },
                {
                    heading: '2.0 User Agreement:',
                    content: [
                        'This User Agreement (“Agreement “) is a legal agreement between you (referred to as ‘User’, ‘Recipient’, ‘You’, ‘Member’ And ‘Your’) and www.YourIndianCart.com (referred to as ‘YourIndianCart’, ‘We’, ‘Us’, or ‘Our’) is owned and operated by PRI10 E-Solutions Private Limited, a Sole Proprietary registered in India under Company Identification Number (CIN) U74999TN2022PTC155253.',
                        'All services provided by YourIndianCart are described in www.YourIndianCart.com and are collectively referred to in this Agreement as “Services.” As a condition of your use of this Site and Services, you represent and warrant to YourIndianCart that you will not use this Site or the Services for any purpose that is unlawful, immoral or prohibited by these terms, conditions and notices.',
                        'This Agreement supplements the terms and conditions of the Services described in the Site. By accessing the Site or opening a new account with YourIndianCart.com, you agree to be bound by and hereby become a party to all the terms of this Agreement. If you do not agree with the terms and conditions in this Agreement, you may not use YourIndianCart.com or its Services.',
                        'From time to time and as needed or required by law, we will amend, modify, or change the terms of this Agreement by making those changes directly to the Agreement on this Site. These changes take effect when posted to the site. By continuing to access or use YourIndianCart.com after any such amendment, you agree to be bound by the terms of the amended Agreement.',
                        'Please read the entire terms and conditions of use carefully before you use YourIndianCart.com. If you do not agree to all the terms and conditions, you may not use the site. By using the site, you are deemed to have read and accepted these terms and conditions and are at least 18 years of age',
                        'You agree not to use the Site or the Services to:',
                        [
                            'Harm or threaten to harm YourIndianCart or users in any way, including by using information you find here to compete against YourIndianCart;',
                            'Impersonate any person or entity (including YourIndianCart or its agent), or falsely state or otherwise misrepresent your affiliation with a person or entity;',
                            'Disseminate or transmit any content that infringes or violates any party’s copyright, trademark, trade secret, patent or other rights;',
                            'Violate any law (whether local, state, national, or international), whether or not intentionally;',
                            'Forge Internet protocol headers or otherwise manipulate identifying information in order to disguise your identity;',
                            'Provide fraudulent, inaccurate, or incomplete information to YourIndianCart or any shipping service or government agency;',
                            'Interfere with or disrupt the Services, the Site, or servers or networks connected to the Site, or disobey any requirements, procedures, policies, or regulations of networks connected to the Site;',
                            'Collect or store personal data about other users;',
                            'Engage in any activities that would violate the personal privacy rights of others, including but not limited to, collecting and distributing information about Internet users without their permission, except as permitted by applicable law;',
                            'or post or cause to be uploaded to YourIndianCart’s computer servers or network any material that contains software viruses, or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.',
                        ],
                    ]
                },
                {
                    heading: '3.0 Our Site',
                },
                {
                    heading: '3.1 No Automated Queries:',
                    content: [
                        'You may not send automated queries of any sort to the Site without express permission in advance from YourIndianCart. Note that “sending automated queries” includes, among other things:',
                        [
                            'using any software that sends queries to the Site to determine how a website or web page “ranks” for various queries;',
                            '“meta-searching”; and',
                            'performing “offline” searches on any YourIndianCart website.',
                        ]
                    ]
                },
                {
                    heading: '3.2 No Copies',
                    content: [
                        'You may not (and shall not allow any third party to) copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sub license, grant a security interest in or otherwise transfer any right in the Services or the Site, or remove or obscure the copyright notice or other notices displayed on the Site. Except as provided above, you may not reproduce, print, cache, store or distribute content retrieved from the Site in any way, for any commercial use without the prior written permission of YourIndianCart.',
                    ]
                },
                {
                    heading: '3.3 Copyrights',
                    content: [
                        'All content available on YourIndianCart.com, including site design, text, graphics, interfaces and the selection of arrangements thereof is copyrighted by YourIndianCart, with all rights reserved, or is the property of YourIndianCart or third parties protected by intellectual property rights.',
                        'If you believe content on YourIndianCart.com infringes your copyright, you should send notice of copyright infringement to YourIndianCart.',
                        'The notice should be emailed to: support@YourIndianCart.com',
                        'Upon receipt of notice, YourIndianCart.com will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from YourIndianCart.com.',
                    ]
                },
                {
                    heading: '3.4 Registration',
                    content: [
                        'Before you use the Services, you must open a YourIndianCart Account by completing the YourIndianCart sign up form with true, accurate, current and complete information about yourself (“Registration Information”). You agree to maintain and update the Registration Information to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or if YourIndianCart has reasonable grounds to suspect that information you have provided is untrue, inaccurate, not current or incomplete, YourIndianCart has the right to suspend or terminate your access and refuse you any and all use of the Site and the Services.',
                    ]
                },
                {
                    heading: '3.5 Fraud Prevention',
                    content: [
                        'YourIndianCart is continually working to prevent any type of fraudulent activity. We leverage third party tools to prevent payment fraud, various industry standards to protect your account information and may, at any time, update our methods and tools. We also require that all items you send to our facilities have been purchased by you and paid for in full. If we are notified that fraudulent activity has been detected in any fashion, we reserve the right to refuse shipment until such time the seller has verified the transaction or as we otherwise determine in our sole discretion. Please send any suspected fraud inquiries to support@YourIndianCart.com',
                    ]
                },
                {
                    heading: '3.6 Password',
                    content: [
                        'When your registration is complete, you may access your individual YourIndianCart Account and other secure areas of YourIndianCart.com using a password. You are responsible for safeguarding the password. You agree not to disclose your password to any third party. You agree to take sole responsibility for any activities or actions under your password, whether or not you have authorized such activities or actions. You will immediately notify YourIndianCart.com of any unauthorized use of your password.',
                    ]
                },
                {
                    heading: '3.7 Prohibited Items',
                    content: [
                        'YourIndianCart does not ship weapons (including items that assist in the functionality or manufacturing of firearms/ammunition or replicas of such items) or narcotics or other prohibited items. In addition, certain items may require special permissions or documentation or may be prohibited by your destination address or country. It is your responsibility to know these restrictions.',
                        'For more information on Prohibited Items please see Prohibited Items page under the services section on the home page.',
                        'YourIndianCart cooperates with law enforcement agencies to ensure that prohibited items are not shipped via YourIndianCart. This includes the routine inspection by law enforcement agencies of YourIndianCart’s facilities and its customers’ packages. By entering into this agreement, you accept and agree to routine inspections of your packages by law enforcement agencies. If your package is found to contain prohibited items, you agree that the contents of your package may be seized and confiscated by law enforcement or YourIndianCart. If prohibited items are found in your packages as determined by law enforcement or YourIndianCart, your account will be placed on hold, and any additional packages within your Locker may also be inspected and confiscated.'
                    ]
                },
                {
                    heading: '4.0 Our Services',
                },
                {
                    heading: '4.1 Addresses',
                    content: [
                        'Each package sent to YourIndianCart’s address must be addressed correctly. Your correct name and YourIndianCart Suite number must be written on each package. If a package arrives with an incorrect or incomplete address (for example missing Suite number or an unknown or misspelled name), YourIndianCart will try to determine whom it belongs to. If YourIndianCart is able to determine the owner of the package, it will be entered into the correct suite. If the owner cannot be determined the package will be disposed of at the sole discretion of YourIndianCart. Your Indian address can be found under the account settings section once you have signed up for an account.',
                    ]
                },
                {
                    heading: '4.3 Storage',
                    content: [
                        'YourIndianCart will store your packages in our warehouse for up to 30 days for without charge while we wait to receive all of the packages which has been ordered. However, if your package needs to be stored in our warehouse for more than the above period you may opt to extend the storage time for a fee or the parcel may be considered abandoned.',
                    ]
                },
                {
                    heading: '4.4 Abandoned Packages',
                    content: [
                        'YourIndianCart will dispose of any abandoned/unclaimed packages.',
                    ]
                },
                {
                    heading: '4.5 Package Refusal/Return to Sender',
                    content: [
                        'We are not able to refuse receipt of packages from carriers. Packages can be returned to vendors after they are processed into your account via Return Request which is free of charge.',
                    ]
                },
                {
                    heading: '4.6 Oversized Packages',
                    content: [
                        'Oversized packages are any package over the size or weight limits set forth in the by the specified carrier. Oversized packages are required to be divided, reduced, disposed of or returned to the sender if such packages are beyond the limits of the specified carrier. Maximum piece weight should not exceed 70kg and maximum dimensions should not exceed 274 cm in length',
                    ]
                },
                {
                    heading: '4.7 Photographs of Packages',
                    content: [
                        'Upon your request, YourIndianCart will open and photograph the contents of a package, allowing you to confirm the merchandise is correct and in good condition before requesting Return Service. Up to 3 photographs will be uploaded against each item receive. You should be able to see them under your dashboard.',
                    ]
                },
                {
                    heading: '4.8 Consolidation',
                    content: [
                        'YourIndianCart is not responsible for items missing from a consolidated package without proof that the missing item was received by us. All items received by YourIndianCart is photographed and loaded into your account. We will remove all extra packaging during consolidation which will reduce the amount of space occupied in the shipping box. This helps bring down the shipping costs.   ',
                    ]
                },
                {
                    heading: '4.9 Operations Timelines:',
                    content: [
                        'Please be advised of the operations timelines to complete each process:',
                        'Inbound – 24 to 48 hours from the time the parcel is delivered to our warehouse.',
                        'Consolidation – 24 to 48 hours from the time you request consolidation.',
                        'Dispatch – 24 to 48 hours from the time you pay the shipping charge.',
                        'Operations working hours: Mon-Fri 10AM-6PM. ',
                    ]
                },
                {
                    heading: '5.0 YourIndianCart (YIC) Store.',
                },
                {
                    heading: '5.1 Orders',
                },

                // {
                // 	heading: '',
                // 	content: [
                // 		'',
                // 	]
                // },

            ]
        };
    },
};

</script>
<style scoped>
.root_terms {
    color: #000;
    padding: 0 8rem;
    background-color: white;
}

.heading_terms {
    font-weight: 600;
    font-size: 43px;
    color: #414141;
    margin: 2rem 0;
    margin-bottom: 0rem;

}

.text_terms {
    display: flex;
    flex-direction: column;
    text-align: justify;
}



.heading__ {
    font-weight: 700;
    font-size: 20px;
    color: #000;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.heading__>ul {
    color: #000;
    font-size: 15px;
    font-weight: 400;
}


/*========================= Media Queries (Medium devices) =========================*/
@media screen and (max-width: 1000px) {
    .root_terms {
        padding: 0 5rem;
    }
}

/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {
    .root_terms {
        padding: 0 2rem;
    }
}
</style>